import './App.css';
import { About } from './components/About/About';
import Contact from './components/Contact/Contact';
import { Home } from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Portfolio from './components/Portfolio/Portfolio';
import { Services } from './components/Services/Services';
import Social from './components/Social/Social';

function App() {
  return (
    
    <div className="App">
        <Navbar/>
        <Home/>
        <About/>
        <Services/>
        <Portfolio/>
        <Contact/>
        <Social/>
    </div>
  );
}

export default App;
