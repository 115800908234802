
import React from 'react';
import './Navbar.css';
import { Link} from 'react-scroll';
import borboleta from '../../images/borboleta.png';

const Navbar = () => {
  return (
        <div className="navbar">
            <div className="nav-title">
               <img src={borboleta} alt="" />
            </div>
            <div className="nav-list">
                <ul>
                <Link spy={true} to='Navbar' smooth={true} activeClass='activeClass'>
                    <li>Home</li>
                </Link>
                <Link spy={true} to='About' smooth={true} >
                     <li>About</li>
                  </Link>
                <Link spy={true} to='Services' smooth={true} >
                    <li>Services</li>
                </Link>
                <Link spy={true} to='Portfolio' smooth={true} >
                     <li>Portfolio</li>
                </Link>
                <Link spy={true} to='Contact' smooth={true} >
                     <li>Contact</li>
                </Link>
                <Link spy={true} to='Social' smooth={true} >
                     <li>Midias</li>
                </Link>
                </ul>
            </div>
        </div>
      )
}

export default Navbar