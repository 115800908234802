import React from 'react';
import './Services.css';
import Page from '../../images/internet1.png';
import Internet from '../../images/internet2.png';
import Landing from '../../images/internet3.png';
import Web from '../../images/internet4.png';
import Resume from './resume.pdf';
import {motion} from 'framer-motion';


export const Services = () => {

    const transition = {duration: 2, type: 'spring'}

  return (
    <div className='services' id='Services'>
        <div className="services-left">
            <div className="services-small-title">
                <span>My Services</span>
            </div>
            <div className="services-title">
                <h1>What I <span>Do</span></h1>
                <hr />
            </div>
            
            <div className="services-txt">
                <p>
                    Creation of: Landing Pages,
                    Websites, Interfaces and much more!
                </p>
                <br />
                <p>
                    Fully adaptable pages with 
                    tablets and smartphones!
                </p>
            </div>
            <div  className='button'>
               <a href={Resume} download>
                 <button className="btn" >Download CV</button>
               </a>
            </div>
        </div>
        <div className="services-right">
            <div className="services-cart-left">
                <div className="cart">
                    <div className="cart-txt">
                        <img src={Page} alt="" />
                        <h1>Pages</h1>
                        <span>
                            Creative and responsives pages
                        </span>
                    </div>
                </div>
                <div className="cart">
                    <div className="cart-txt">
                        <img src={Landing} alt="" />
                        <h1>Landing Pages</h1>
                        <span>
                            Creative and responsives 
                            Landing Page
                        </span>
                    </div>
                </div>
            </div>
            <div className="services-cart-right">
                <div className="cart">
                    <div className="cart-txt">
                    <img src={Internet} alt="" />
                    <h1>Interfaces</h1>
                    <span>
                        Creative and responsives interfaces
                    </span>
                    </div>
                </div>
                <div className="cart">
                    <div className="cart-txt">
                        <img src={Web} alt="" />
                        <h1>Website</h1>
                        <span>
                            Creative and responsives 
                            Websites
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
