import './Contact.css';
import React, { useRef, useState} from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {

    const form = useRef();
    const [done, setDone] = useState(false);
    const [name, setName]= useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
   
    const sendEmail = (e) => {
      e.preventDefault();

      if(name === ''|| email === ''|| message === ''){
        alert('Enter all data!')
        return;
      }

      emailjs.sendForm('service_k4uh4sc', 'template_6b56v6q', form.current, 'J0eBv3aLGCrjtZLwA')
        .then((result) => {
            console.log(result.text);
            setName('');
            setEmail('');
            setMessage('');
            setDone(true);
        }, (error) => {
            console.log(error.text);
        });
        
    };

  return (
    <div className='contact' id='Contact'>
        <div className="contact-left">
            <div>
                <h1>Contact me</h1>
                <hr />
            </div>
            <div>
                <span>
                    Feel free to send me an email with 
                    suggestions, criticisms, job or even hire me!
                </span>
            </div>
        </div>
        <div className="contact-right">
            
               <form ref={form} onSubmit={sendEmail}>
                    <input 
                    type="text"
                    name='user_name' 
                    className='user' 
                    value={name} 
                    onChange={(e)=> setName(e.target.value)} 
                    placeholder='Name'  />
                    <input type="email" 
                    name='user_email' 
                    className='user' 
                    value={email} 
                    onChange={(e)=> setEmail(e.target.value)}
                    placeholder='Email' />
                    <textarea 
                    name='message' 
                    className='user'
                    value={message} 
                    onChange={(e=> setMessage(e.target.value))}
                    placeholder='Message' />
                    <button type="submit" value='Send' className='btn' onClick={sendEmail}> Send</button>
                    <span>{done && 'Thanks for contacting me!'}</span>
               </form>
            </div>
        </div>
  )
}

export default Contact