import React from 'react'; 
import './Portfolio.css';
import design from '../../images/design.png';

const Portfolio = () => {
  return (
    <div className='portfolio' id='Portfolio'>
       <div className='portfolio-left'>
           <img src={design} alt="" />
       </div>
       
       <div className='portfolio-right'>
            <span>My recents Projects</span>
            <h1>Portfolio</h1>
            <hr />
            <p>
                All projects were made with React JS.
                Among the projects are the calculator IMC, a photo 
                gallery where you can add and remove photos.
                We have a small personal finance system, where 
                it is possible to add and remove items and 
                calculate the positive or negative balance at 
                the end of the month.
                We have a small memory game and a Mini Blog, 
                where it is possible to register, having 
                protected routes, only those who are logged in 
                can insert, remove or edit a post, it is also 
                possible to search for posts with words or 
                hashtags.
            </p>                    
          </div>
    </div>
  )
}

export default Portfolio