import React from 'react';
import './Social.css';
import Insta from '@iconscout/react-unicons/icons/uil-instagram';
import Facebook from '@iconscout/react-unicons/icons/uil-facebook';
import Github from '@iconscout/react-unicons/icons/uil-github';
import Linkedin from  '@iconscout/react-unicons/icons/uil-linkedin';
import Telegram from  '@iconscout/react-unicons/icons/uil-telegram';
import Whatsapp  from '@iconscout/react-unicons/icons/uil-whatsapp';

const Social = () => {
  return (
    <div className='social' id='Social'>
        <div className="icons">
          <a href='https://www.instagram.com/geisasouza92/' target='_blank'>
              <Insta color='#7CBEAE' size= '4rem'/>
          </a>
          <a href="https://www.facebook.com/geisa.santos.505523"  target='_blank'>
              <Facebook color='#7CBEAE' size= '4rem'/>
          </a>
          <a href="https://github.com/GeisaSantos92"  target='_blank'>
          <Github color='#7CBEAE' size= '4rem'/>
          </a>
          <a href="https://www.linkedin.com/in/geisa-santos-93568a249/"  target='_blank'>
              <Linkedin color='#7CBEAE' size= '4rem'/>
          </a>
        <a href="https://t.me/geisassantos"  target='_blank'>
            <Telegram color='#7CBEAE' size= '4rem'/>
        </a>
          <a href="https://wa.me/5534992555395"  target='_blank'>
            <Whatsapp color='#7CBEAE' size= '4rem'/>
          </a>
        </div>
    </div>
  )
}

export default Social