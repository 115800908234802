import React from 'react';
import './About.css';



export const About = () => {
  return (
    <div className='about' id='About'>
      <div className="about-left">
          <div className="small-title">
            <span>About me</span>
          </div>
          <div className="about-title">
            <h1>I am <span>Web</span></h1>
            <h1>Developer</h1>  
            <hr />        
          </div>     
       </div>
      <div className="about-right">
        
         <div className="right-txt">
          <p>
            Hi! My name is Geisa, I've been studying programming (front-end) for almost one year..
            I am open to learning new technologies!
            I'm a very dedicated person, I'm very proud to have made it this far alone,
            with a lot of willpower and passion for programming!
            I still don't have experience in the job market, but I would really like a chance
            to show my full potential!
          </p>
         </div>
      </div>
    </div>
  )
}
